import './bootstrap'
import '../css/app.css'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { apiPlugin, StoryblokVue } from '@storyblok/vue'
import VueSocialSharing from 'vue-social-sharing'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import { defineResolvers, plugin as srtPlugin } from '@marvr/storyblok-rich-text-vue-renderer'
import { i18nVue } from 'laravel-vue-i18n'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'TellSaleem'

createInertiaApp({
  title: (title) => `${title} | TellSaleem`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob(['./Pages/Patient/**/*.vue'])),
  setup({ el, app, props, plugin }) {
    return createApp({ render: () => h(app, props) })
      .use(plugin)
      .use(i18nVue, {
        resolve: async (lang) => {
          const langs = import.meta.glob('../../lang/*.json')
          return await langs[`../../lang/${lang}.json`]()
        },
      })
      .use(StoryblokVue, {
        // bridge: process.env.NODE_ENV !== "production",
        accessToken: import.meta.env.VITE_STORYBLOK_PUBLIC_KEY,
        cache: {
          clear: 'auto',
          type: 'memory',
        },
        use: [apiPlugin],
      })
      .use(
        srtPlugin({
          // optional if you have custom SB components that you want to map to Vue components
          resolvers: defineResolvers({
            components: {},
          }),
        }),
      )
      .use(VueSocialSharing.default)
      .use(ZiggyVue, Ziggy)
      .mount(el)
  },
})

InertiaProgress.init({ color: '#4B5563' })
